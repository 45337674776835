import React, { useEffect } from 'react';
import CartItem from '../components/CartItem';
import Container from '../components/Container';
import Footer from '../components/Footer';
import OrderSummary from '../components/OrderSummary';

import * as styles from './cart.module.css';
import Header from '../components/Header';
import { useMedia } from 'use-media';
import { Spacer } from '../uiBook';
import { useAppContext } from '../context/useAppContext';

const CartPage = (props) => {
  const [cartItems, setCartItems] = React.useState([]);
  const { basket } = useAppContext();
  const isMobile = useMedia({ maxWidth: '480px' });

  useEffect(() => {
    const items = basket;
    if (items?.length) {
      const itemObjects = items.map((item) => {
        return {
          name: item.title,
          price: item.price,
          image: item.images.split(',')[0],
          slug: item.slug,
          alt: '',
        };
      });
      setCartItems(itemObjects);
    }
  }, [basket]);

  return (
    <div>
      <Header />
      <div className={styles.contentContainer}>
        <Container size={'large'} spacing={'min'}>
          {/* <div className={styles.headerContainer}>
            <div className={styles.shoppingContainer}>
            <Link className={styles.shopLink} to={'/shop'}>
            <Icon symbol={'arrow'}></Icon>
            <span className={styles.continueShopping}>
            Continue Shopping
            </span>
            </Link>
            </div>
            <Brand />
            <div className={styles.loginContainer}>
            <Link to={'/login'}>Login</Link>
            </div>
          </div> */}
          <div className={styles.summaryContainer}>
            <Spacer height={isMobile ? 80 : 0} />
            <h3>Basket</h3>
            <div className={styles.cartContainer}>
              <div className={styles.cartItemsContainer}>
                {cartItems.map((item) => {
                  return <CartItem {...item} key={item.name} />;
                })}
              </div>
              <OrderSummary />
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
